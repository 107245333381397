export const cosmeticDentistry = [
    {
        question: 'What is cosmetic dentistry?',
        answer: 'Cosmetic dentistry is the process of making dental enhancements to tooth color, shape, size, alignment, or smile appearance. The main goal of cosmetic dentistry is to improve the overall aesthetics of your teeth.',
    },
    {
        question: 'What services are included in Cosmetic dentistry?',
        answer: `
    Modern cosmetic dentistry is a method of professional oral care that focuses on improving your mouth and teeth. Tooth whitening, fillings, and implants: 
    these services can help you feel better about your smile. `,
    },
    {
        question: 'Are Veneers worth it?',
        answer: 'A long-term investment, veneers last for 10 years or more. A lot of people find the value of a nicer smile to be worth the cost and hassle of getting it done.',
    },
    {
        question: 'What is the difference between crowns and veneers?',
        answer: 'Dental restorations are powerful tools for improving the appearance of your teeth. There are two main types of restoration: crowns and veneers. Crowns cover the entire tooth, while veneers cover just the front.',
    },
];

export const porcelainVeneers = [
    {
        question: 'Are porcelain veneers good for me?',
        answer: `Porcelain veneers are used on healthy teeth to change the appearance of one's smile. However, if you have tooth decay or gum disease, you would have to get that taken care of before being fitted for porcelain veneers. If you have a habit of grinding your teeth or clenching your jaw, then this may not be the best option for you. Despite their incredible strength, excessive clenching or grinding overtime may damage them.
        At Smile Tampa, Dr. Reza Iranmanesh is determined to find out if you're a good candidate for porcelain veneers. If he feels that you're not a candidate, he'll work with you to find a more suitable treatment based on his years of experience. Dr. Reza  is a wonderful dentist with a range of procedures to offer. No matter what your situation, there's a solution for you.`,
    },
    {
        question: 'What is the expected lifespan of porcelain veneers?',
        answer: `Porcelain veneers are a long-lasting restoration, but they do have general wear and tear. The longevity of porcelain veneers is dependent on your oral care habits. If you often eat hard candy or other hard foods that put pressure on the veneers, they may wear down over time. Grinding teeth or mouth injuries can also damage them.`,
    },
    {
        question: 'How much do porcelain veneers cost?',
        answer: `The cost of porcelain veneers can vary. Some factors that affect the cost are the materials and the type of procedure that will be used, though they are usually several hundred dollars per tooth.
        Dr. Reza Iranmanesh will discuss with you both of these factors during your consultation and provide a treatment program plan, as well as a price estimate.
        `,
    },
    {
        question: 'Will my Veneers look natural?',
        answer: `​​The average life expectancy for porcelain veneers is 10 years, but it's not uncommon for them to last as long as 20 years with proper care and maintenance.`,
    },
    {
        question: 'Do you brush porcelain veneers?',
        answer: `Yes, to protect your teeth from decay, maintain a healthy oral hygiene routine. Brush at least twice a day with fluoride toothpaste and floss once a day.`,
    },
];

export const implantCrowns = [
    {
        question: 'How much do implant crowns cost?',
        answer: `Porcelain veneers are used on healthy teeth to change the appearance of one's smile. However, if you have tooth decay or gum disease, you would have to get that taken care of before being fitted for porcelain veneers. If you have a habit of grinding your teeth or clenching your jaw, then this may not be the best option for you. Despite their incredible strength, excessive clenching or grinding overtime may damage them.
        At Smile Tampa, Dr. Reza Iranmanesh is determined to find out if you're a good candidate for porcelain veneers. If he feels that you're not a candidate, he'll work with you to find a more suitable treatment based on his years of experience. Dr. Reza is a wonderful dentist with a range of procedures to offer. No matter what your situation, there's a solution for you.`,
    },
    {
        question: 'What is an implant crown?',
        answer: `
        Dental implants can be fitted with crowns just like natural teeth. These devices sit on the external-facing end of the implant and are made to look just like teeth.`,
    },
    {
        question: 'Is an implant crown different than a regular crown',
        answer: `A dental implant crown is a durable, permanent solution to replacing an entire tooth or teeth. Unlike dental crowns that sit above the gumline, implants are placed below the gumline and provide a screw to let you attach an implant crown.`,
    },
];

export const porcelainBridges = [
    {
        question: `Why go for a porcelain dental bridge?`,
        answer: `They can be used for a variety of reasons, some of which include fixing your smile, maintaining overall facial shape, and filling the space left by missing teeth.`,
    },
    {
        question: `Is porcelain dental bridge permanent?`,
        answer: `Yes it is typically a fixed (permanent) restoration.`,
    },
];

export const implantRetainedDentures = [
    {
        question: `How much do implant supported dentures cost?`,
        answer: `What is the cost of implant supported dentures? Implant supported dentures are a permanent solution for missing teeth. They promise the best of both worlds: dental health and good looks. A set of custom porcelain dentures will run you around $1,500 to $6,000.`,
    },
    {
        question: `What are implant supported dentures?`,
        answer: `Conventional dentures can cause gum irritation and may slip. Implant-supported dentures anchor to the jawbone and don't slip at all. They're comfortable, and patients love to be able to eat, talk, and smile with confidence.`,
    },
    {
        question: `How to clean implant supported dentures?`,
        answer: `To clean dentures, use a soft-bristled toothbrush and non-abrasive toothpaste. Brush your gums, tongue, and all the tissues of your mouth with care to avoid gum disease.`,
    },
    {
        question: `Are implant supported dentures removable?`,
        answer: `Just like with conventional dentures, you'll want to remove and clean your implant-supported dentures daily.`,
    },
];

export const dentalImplants = [
    {
        question: `What are dental implants?`,
        answer: `A dental implant is a small metal rod that substitutes for the root of your tooth. The connector, known as an abutment, is fused to the end of the rod. This connection is then attached to your new replacement tooth by a crown.`,
    },
    {
        question: `How much do dental implants cost?`,
        answer: `The average cost of dental implants is $3,000 — $5,000. It includes the post (surgery), an abutment (a small metal screw), and crown placement.`,
    },
    {
        question: `How long do dental implants last?`,
        answer: `A dental implant can last a lifetime if it is taken care of with regular brushing and flossing. The crown, however, will only last about 10 to 15 years before it needs replacement due to wear and tear.`,
    },
    {
        question: `Do dental implants hurt?`,
        answer: `Dental implants are not as painful as you might think, and this is thanks to modern advancements in pain management. Not only that, but dental specialists are very experienced in this field and will place the implant in such a way that minimizes pain post-operation.`,
    },
    {
        question: `How do dental implants work?`,
        answer: `Dental implants are metal fixtures, surgically fastened to the jawbone below the gums. The bone fuses with the metal through osseointegration so it becomes anchored.`,
    },
    {
        question: `How much do full dental implants cost?`,
        answer: `A full mouth of implant dentures can cost on average $34,000. A denture can range from $3,500 to $30,000. The difference is that the implant is more durable and secure. They are not reliant on adhesives either unlike traditional dentures.`,
    },
];

export const fourOnSix = [
    {
        question: `How long do all-on-4 implants last?`,
        answer: `All-on-4 implants last up to 20 years, but onlyif the patient has a healthy bone and a
    stable gum. One way to ensure this is to maintain good oral health, such as brushing
    and flossing every day.`,
    },
    {
        question: `What are the benefits of All-on-4 and all-on-6 dental implants?`,
        answer: `Unlike a traditional set of dentures, implant-supported dentures do not have to be
    removed for cleaning. Patients who use the All-on-4 system have a lot less work to do in
    order to maintain their dentures. They do not have to worry about implants being
    unstable and damaging their jaw or causing bad dental hygiene. The implants are stable
    and allow patients to brush and floss just like they would normal teeth.`,
    },
    {
        question: `What are all-on-4 teeth implants in dentistry?`,
        answer: `All-on-4 is a type of dental implant that replaces missing teeth on any side of your
    mouth. This device provides a snug, comfortable fit and excellent results. It is secured
    by four dental implants and a denture appliance.`,
    },
    {
        question: `What foods are safe post all-on-4 implant surgery?`,
        answer: `In the first two weeks after your procedure, youwill be on a liquid diet. Your mouth, jaw,
    and throat will likely hurt during this time. Even if the pain or swelling  has subsided at
    this point, eating will still be hard. A liquid diet will make it easier because you will not
    need to chew.`,
    },
];
